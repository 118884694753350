
.pagetitle {
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
}
@media all and (max-width: 800px) {
  h2 {
    margin: 1rem;
  }
  .pagetitle {
    text-align: center;
    width: 100%;
  }
}
.section1 {
  width: 80%;
/*  margin: 30px auto; */
  max-width: 800px;
  background: #cd3e3f;

  margin: 20px auto 0 auto;
  -webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

.subtitles {
  width: 80%;
/*  margin: 30px auto; */
  max-width: 800px;
  /*background: #cd3e3f;*/
  font-weight: bold;
  margin: 20px auto 0 auto;
  font-size: 28px;
  color: #cd3e3f;
  border-bottom: 1px solid #cd3e3f;

  /*-webkit-box-shadow: rgba(0,0,0,0.8) 0px 0 10px;
  -moz-box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  box-shadow: rgba(0,0,0,0.8) 0 0 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;*/
}

.section1 ul {
  list-style-type: none;
}

.nav-link-scroll {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-link-scroll:hover {
    color: white;
    background: #d75859;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    transition: all 0.2s ease-out;
}

@media screen and (max-width: 800px) {
     .section1 {
       width: 100%;
       margin: 0 auto;
       margin-top: 20px;
     }

     .subtitles {
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
      font-size: 22px;
    }

     .nav-link-scroll {
        text-align: center;
        padding: 0.3rem;
        width: 100%;
        display: table;
    }

}
