.BannerDiv {
    background: #fff;
    display:flex;
    height: 70px;
    margin: 0;
}
.Banner-logo {
    background-image: url("../../assets/images/banniere_uib1_billboard.png");
    background-size:auto 100%;
    background-position: right; 
    background-repeat: no-repeat;
    display:flex;
    height: 70px;
    width: 100%;
    margin: 0;
}
h1.Banner-logo1{
    /*border: 1px solid #ddd;
    border-radius: 4px;*/
    width: 550px; 
    padding-left: 10px;
    padding-top: 20px;
    border-left: 1px solid #cd3e3f;
}
.Banner-logo2{
    width: 100%;
    text-align:right;
    margin-top: -10px;
}
.language-select {
  float: right;
  margin-top: 0px;
}
.logo {
  width: 90px;
}
img {
    border-radius: 0px;
    height: 50px;
    margin: 10px;
}

