.flex img {
  display: block;
  max-height: 400px;
  max-width: 400px;
  width: auto;
  height: auto;
}

.flex {
  margin: 0;
  display: flex;
  border-bottom: 5px solid #333;
  background-color: #fff;
  margin-bottom: 2rem;
}
/* optional: alternately flips module layout 
.flex:nth-child(odd) {
  flex-direction: row-reverse;
}
*/
.flex div:first-child {
  flex: 1;
  background-size: cover;
  background-position: center;
  margin: auto;
  width: 50%;
}
/*
.flex:first-of-type div:first-child {
  background-image: url("../../assets/images/accueil/tom2.jpg");
}
.flex:last-of-type div:first-child {
  background-image: url("../../assets/images/accueil/Realfagsbygget.jpg");
}
*/
.flex div:last-child {
  margin: 2rem;
  flex: 2;
}
.flex h3 {
  font-size: 1.5rem;
  margin-top: 0;
  font-weight: 400;
}
.flex p {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 400;
  white-space: pre-wrap;
}
.flex p:last-of-type {
  margin-bottom: 0;
}
.column {
  width: 80%;
  margin: 0 auto;
  max-width: 800px;
}

@media all and (max-width: 800px) {
  body { margin: 0; }
  /* .flex div { flex: 1 !important; } optional: equalizes the visual "weight" of the photo and description */
}
@media all and (max-width: 800px) {
  h2 {
    margin: 1rem;
  }
  .flex {
    border: 0;
    margin: 0;
    border-bottom: 2px solid #333;
  }
  .flex div:last-child {
    margin: 1rem;
  }
  .column {
    width: 100%;
  }
}
@media all and (max-width: 800px) {
  .flex { flex-direction: column; } 
  .flex div:first-child {
    min-height: 200px;
  }
}

